<template>
  <div class="row tour-settings" v-if="!loading">
    <p class="tour-settings-title">Tour settings</p>
    <div class="section">
      <h5>Tour name</h5>
      <div class="content">
        <DefaultTextInput
          v-model="tourData.name"
          @change="updateTourSettings()"
          :maxlength="50"
        />
      </div>
    </div>
    <div class="section">
      <h5>
        Publish
        <DefaultToggle
          v-model="tourData.isPublished"
          @input="togglePublishedStatus()"
        />
      </h5>

      <div class="content">
        <p class="w-75">
          When enabled the tour will be visible and searchable.
        </p>
      </div>
    </div>
    <div class="section">
      <h5>Tour type</h5>

      <div class="content">
        <p class="w-100 mb-4">
          Choose the type of tour you’re hosting so that players can find it
          more easily when searching.
        </p>
        <DefaultRadioButton
          class="tour-type-radio mb-4"
          v-for="type in tourTypes"
          :key="type.type"
          groupname="tour-type"
          :value="tourData.type"
          :options="{ title: type.name, value: type }"
          @change="
            tourData.type = $event;
            updateTourSettings();
          "
        />
        <DefaultRadioButton
          class="tour-type-radio"
          groupname="tour-type"
          :value="tourData.type"
          :options="{ title: 'Other', value: null }"
          @change="
            tourData.type = $event;
            updateTourSettings();
          "
        />
      </div>
    </div>
    <div class="section">
      <h5>Registration type</h5>

      <div class="content vertical">
        <DefaultRadioButton
          class="mb-4"
          groupname="signup-type"
          :value="tourData.registrationMethod"
          @change="
            tourData.registrationMethod = $event;
            updateTourSettings();
          "
          :options="{
            title: 'Waiting list',
            value: 'LIST',
            disabled: registrationMethodSwitchDisabled,
            description:
              'Standard registration where players are placed on a waiting list and spots are granted by the TD.',
          }"
        />
        <DefaultRadioButton
          class="mb-4"
          groupname="signup-type"
          :value="tourData.registrationMethod"
          @change="
            tourData.registrationMethod = $event;
            updateTourSettings();
          "
          :options="{
            title: 'Groups',
            value: 'GROUPS',
            disabled: registrationMethodSwitchDisabled,
            description:
              'A more casual registration where players choose a group to play in (note: groups can still be adjusted by the TD).',
          }"
        />

        <p class="mt-3" v-if="registrationMethodSwitchDisabled">
          The registration method can only be changed if there are no events in
          progress and no registrations open.
        </p>
      </div>
      <div class="content extra-margin">
        <b
          >Registration notes
          <DefaultToggle
            v-model="tourData.allowRegistrationMessage"
            @input="toggleRegMessages($event)"
        /></b>
        <p>
          Allows players to include a note when registering for an event. Only
          event admins can see notes.
        </p>
      </div>
    </div>
    <div class="section" id="directpayments">
      <h5>Direct payments</h5>
      <div class="content vertical">
        <b class="mb-3">Connected Stripe account</b>
        <template v-if="tourData.connectedAccount == null">
          <template v-if="me.connectedAccounts.length > 0">
            <select
              v-model="accountToConnect"
              class="mb-xl-4"
              @change="connectAccount(tourData.id, accountToConnect)"
            >
              <option :value="null">Select account</option>
              <option
                v-for="account in me.connectedAccounts"
                :key="account.id"
                :value="account.id"
              >
                {{ account.name }}
              </option>
            </select>
            <p>
              Direct payments are not enabled on this tour. Choose a Stripe
              account from the menu above or go to your
              <router-link to="/profile" class="mr-1">profile</router-link>page
              to set up and link another Stripe account to Tjing.
            </p>
          </template>
          <template v-else>
            <p>
              Getting started with direct payments only takes a few minutes. All
              you need to do is create a free Stripe account and connect your
              bank. Then simply link your Stripe account to your Tjing account.
              If you already have a Stripe account then you just need to link
              that to Tjing.
            </p>
            <p>
              Go to your
              <router-link to="/profile" class="mr-1">profile</router-link>page
              to set up and link Stripe to Tjing.
            </p>
          </template>
        </template>
        <template v-else>
          <template v-if="me.connectedAccounts.length > 0">
            <select
              v-model="tourData.connectedAccount"
              class="mb-4"
              @change="
                connectAccount(tourData.id, tourData.connectedAccount.id)
              "
            >
              <option :value="tourData.connectedAccount">
                {{ tourData.connectedAccount.name }}
              </option>
              <option
                v-for="account in filterConnectedAccounts()"
                :key="account.id"
                :value="account"
              >
                {{ account.name }}
              </option>
            </select>
            <p>
              To change to another Stripe account go to your
              <router-link :to="{ name: 'profile' }">profile</router-link
              >&nbsp;page to set up Stripe and link to Tjing. <b>Note:</b> Any
              Stripe accounts that have been linked on an event level will not
              be changed.
            </p>
          </template>
          <template v-else>
            <select v-model="tourData.connectedAccount" class="my-4">
              <option :value="tourData.connectedAccount">
                {{ tourData.connectedAccount.name }}
              </option>
            </select>
            <p>
              If you wish to change what connected account is used you first
              need to add one to your own profile. Please note that after you do
              that you can change it for each event individually if you want.
            </p>
            <button
              class="sky full xl-fluid tall mt-3"
              @click="$router.push({ name: 'profile' })"
            >
              Take me to my Profile
            </button>
          </template>
        </template>
      </div>

      <div
        class="content extra-margin"
        v-if="tourData.connectedAccount != null"
      >
        <b>
          Automatic refunds
          <DefaultToggle
            v-model="tourData.automaticRefunds"
            @input="toggleRefunds()"
          />
        </b>
        <p class="w-75">
          When enabled players will be automatically refunded the registration
          fee if they un-register from the event.
        </p>
        <p class="refund-wrap">
          <b
            ><Tip />If disabled you can refund any amount up to the original
            registration fee.</b
          >
          Go to your Stripe dashboard if you wish to refund a player a fee less
          than the original registration fee.
        </p>
      </div>
    </div>

    <div class="section">
      <h5>Leaderboard</h5>

      <div class="content">
        <div class="left">
          <DefaultRadioButton
            class="mb-3"
            groupname="scoring-method"
            :value="tourData.scoringType"
            @change="
              tourData.scoringType = $event;
              updateTourSettings();
            "
            :options="{
              title: 'Combined results',
              value: 'THROWS',
              disabled: false,
              description: 'Summarise results from events.',
            }"
          />
          <DefaultRadioButton
            class="mb-3"
            groupname="scoring-method"
            :value="tourData.scoringType"
            @change="
              tourData.scoringType = $event;
              updateTourSettings();
            "
            :options="{
              title: 'Average results',
              value: 'AVERAGE_THROWS',
              disabled: false,
              description: 'Average result from events.',
            }"
          />
          <DefaultRadioButton
            class="mb-3"
            groupname="scoring-method"
            :value="tourData.scoringType"
            @change="
              tourData.scoringType = $event;
              updateTourSettings();
            "
            :options="{
              title: 'Combined pars',
              value: 'PAR',
              disabled: false,
              description: 'Summarise results from events.',
            }"
          />
          <DefaultRadioButton
            groupname="scoring-method"
            :value="tourData.scoringType"
            @change="
              tourData.scoringType = $event;
              updateTourSettings();
            "
            :options="{
              title: 'Points',
              value: 'POINTS',
              disabled: false,
              description: 'Summarise points from events.',
            }"
          />
        </div>
        <div class="right">
          <div class="bestof-top">
            <p>
              <b>Based on number of best events</b>
            </p>
          </div>
          <p class="best-of-text">
            Set how many events should be counted on the tournament leaderboard.
          </p>
          <select
            class="number-of-events"
            v-model="tourData.scoreBasedOnNrOfEvents"
            @change="updateTourSettings()"
          >
            <option :value="null">All events</option>
            <option
              :value="n"
              v-for="n in tourData.events.length >= 52
                ? 52
                : tourData.events.length"
              v-if="n > 1"
            >
              {{ n }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="section">
      <h5>Administrators</h5>
      <div class="content vertical">
        <DefaultAddDeleteTextInput
          v-for="admin in tourData.administrators"
          :value="adminNameFormatted(admin)"
          :disabled="admin.role == 'OWNER'"
          :key="admin.id"
          :removeable="true"
          class="mb-3"
          @removeAdmin="removeAdmin(admin)"
        />
        <DefaultAddDeleteTextInput
          placeholder="Add admin email"
          v-model="newAdmin"
          @addAdmin="addAdmin()"
          :invalid="newAdminError"
          @clearError="newAdminError = false"
        />
      </div>
    </div>

    <div class="section">
      <h5>Archive or Delete tour</h5>
      <div class="content vertical archive-tour">
        <b>Archive tour</b>
        <p>
          Once all events in this tour are finished you can choose to archive
          this tour.
          <template v-if="tourData.status == 'COMPLETED'">
            <span
              class="archive"
              v-if="tourData.isArchived"
              @click="toggleArchivedStatus()"
              >Unarchive tour</span
            >
            <span class="archive" v-else @click="toggleArchivedStatus()"
              >Archive tour</span
            >
          </template>
        </p>
      </div>

      <div class="content vertical">
        <b>Delete tour</b>
        <p>
          You can only delete a tour if there haven’t been any player
          registrations.
          <span
            class="delete"
            v-if="tourData.isDeletable"
            @click="$refs.deletemodal.open()"
            >Delete tour</span
          >
        </p>
      </div>

      <sweet-modal
        :enable-mobile-fullscreen="false"
        blocking
        ref="deletemodal"
        hide-close-button
      >
        <h3>Delete tour</h3>
        <p>Are you sure you want to delete this tour? It cannot be undone.</p>

        <div class="buttons">
          <StandardSolidButton
            title="Cancel"
            :fluid="false"
            @click="$refs.deletemodal.close()"
          />
          <StandardBorderedButton
            class="forwards"
            title="Delete"
            :fluid="false"
            @click="deleteTour()"
          />
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import DefaultAddDeleteTextInput from "@/components/UIElements/DefaultAddDeleteTextInput";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import validator from "validator";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Tip from "@/assets/icons/Tip";

export default {
  name: "TourSettings",
  components: {
    DefaultTextInput,
    DefaultToggle,
    DefaultRadioButton,
    DefaultAddDeleteTextInput,
    StandardSolidButton,
    StandardBorderedButton,
    Tip,
  },
  data() {
    return {
      tourData: {},
      me: {},
      accountToConnect: null,
      newAdmin: "",
      loading: true,
      newAdminError: false,
      showAddAdmin: false,
      showDeleteAdminConfirmation: false,
      adminToDelete: {},
      tourTypes: [],
    };
  },
  watch: {
    $route: "loadData",
  },
  computed: {
    stripeTour() {
      if (this.tourData.connectedAccount == null) return false;
      return true;
    },
    registrationMethodSwitchDisabled() {
      for (var i = 0; i < this.tourData.events.length; i++) {
        if (
          this.tourData.events[i].status == "ONGOING" ||
          this.tourData.events[i].isRegistrationOpen ||
          (this.tourData.events[i].status == "CLOSED" &&
            this.tourData.events[i].registrationsCount > 0)
        ) {
          return true;
        }
      }

      return false;
    },
    showOnMobile() {
      return this.$parent.showSubPagesOnMobile;
    },
  },
  methods: {
    async toggleArchivedStatus() {
      try {
        await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation UpdateTour($UpdateTour: UpdateTourInput!) {
                UpdateTour(tourId:"${this.tourData.id}", input: $UpdateTour, asRole:TOUR_ADMIN){
                  id
                }
              }
            `,
            variables: {
              UpdateTour: {
                isArchived: !this.tourData.isArchived,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });

        this.$router.push({ name: "manage" });
      } catch (e) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "success",
        });
      }
    },
    filterConnectedAccounts() {
      let accounts = [];

      this.me.connectedAccounts.forEach((account) => {
        if (account.id != this.tourData.connectedAccount.id) {
          accounts.push(account);
        }
      });

      return accounts;
    },
    async connectAccount(tourId, accountId) {
      if (accountId != null) {
        try {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
            mutation UpdateTour($UpdateTour: UpdateTourInput!) {
              UpdateTour(tourId:"${tourId}", input: $UpdateTour, asRole:TOUR_ADMIN){
                id
              }
            }
          `,
              variables: {
                UpdateTour: {
                  paymentType: "MANAGED",
                  connectedAccountId: accountId,
                },
              },
            },
          });

          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
        } catch (e) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "success",
          });
        }
      }

      this.loadData();
    },
    adminNameFormatted(admin) {
      if (admin.role == "OWNER") {
        return (
          admin.user.firstName + " " + admin.user.lastName + " (tour creator)"
        );
      } else if (
        admin.userId == this.$store.getters.userInfo.sessionInfo.userId
      ) {
        return admin.user.firstName + " " + admin.user.lastName + " (you)";
      }

      return admin.user.firstName + " " + admin.user.lastName;
    },
    toggleRefunds() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateTour($UpdateTour: UpdateTourInput!){
            UpdateTour(tourId:"${this.tourData.id}",input: $UpdateTour, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdateTour: {
              automaticRefunds: this.tourData.automaticRefunds,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Updated",
            type: "success",
          });
          this.loadData();
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    toggleRegMessages(bool) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateTour($UpdateTour: UpdateTourInput!){
            UpdateTour(tourId:"${this.tourData.id}",input: $UpdateTour, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            UpdateTour: {
              allowRegistrationMessage: bool,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Updated",
            type: "success",
          });
          this.loadData();
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    deleteAdminConfirmation(admin) {
      this.adminToDelete = admin;
      this.showDeleteAdminConfirmation = true;
    },
    validEmail(email) {
      if (validator.isEmail(email)) return true;
      return false;
    },

    deleteTour() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            DeleteTour(tourId:"${this.$router.currentRoute.params.tourId}")
          }
          `,
        },
      })
        .then(() => {
          this.$refs.deletemodal.close();
          this.$store.dispatch("loadManagedEvents");
          this.$router.push({ name: "manage" });
        })
        .catch(() => {});
    },
    async togglePublishedStatus() {
      let tourQuery = `
        mutation {
          UnPublishTour(tourId:"${this.$router.currentRoute.params.tourId}"){
            id
          }
        }
      `;
      if (this.tourData.isPublished) {
        tourQuery = `
        mutation {
          PublishTour(tourId:"${this.$router.currentRoute.params.tourId}"){
            id
          }
        }
        `;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: tourQuery,
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.$parent.loadData();
          this.loadData();
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });

      if (this.tourData.isPublished == false) {
        this.tourData.events.forEach((event) => {
          this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
            mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
              UpdateEvent(eventId:"${event.id}", input:$UpdateEvent){
                id
              }
            }
          `,
              variables: {
                UpdateEvent: {
                  isRegistrationOpen: false,
                },
              },
            },
          });
        });
      }
    },
    removeAdmin(admin) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation {
          	DeleteAdministrator(tourId:"${this.$router.currentRoute.params.tourId}",userId:"${admin.userId}"){
            	userId
            }
          }
          `,
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Admin removed",
            type: "success",
          });
          this.loadData();
        })
        .catch(() => {});
    },
    addAdmin() {
      if (this.newAdmin != "") {
        this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            mutation {
              AddAdministratorByEmail(tourId:"${this.$router.currentRoute.params.tourId}",email:"${this.newAdmin}"){
                userId
                user{
                  firstName
                  lastName
                  id
                }
              }
            }
            `,
          },
        })
          .then((result) => {
            if (result.data.data.AddAdministratorByEmail == null) {
              this.newAdminError = true;
            } else {
              this.tourData.administrators.push(
                result.data.data.AddAdministratorByEmail
              );
              this.newAdmin = "";
              this.showAddAdmin = false;

              this.$store.dispatch("showMessage", {
                show: true,
                message: "Admin added",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Error",
              type: "error",
            });
          });
      }
    },
    updateTourSettings() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateTour($UpdateTour: UpdateTourInput!){
            UpdateTour(tourId:"${this.$router.currentRoute.params.tourId}", input:$UpdateTour){
              id
            }
          }
          `,
          variables: {
            UpdateTour: {
              name: this.tourData.name,
              scoringType: this.tourData.scoringType,
              scoreBasedOnNrOfEvents: this.tourData.scoreBasedOnNrOfEvents,
              registrationMethod: this.tourData.registrationMethod,
              type: this.tourData.type == null ? null : this.tourData.type.type,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.$parent.loadData();
          this.loadData();
        })
        .catch(() => {});
    },
    loadData() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me{
              id
              connectedAccounts{
              id
              name
              }
            }
            tourTypes{
              name
              type
            }
            tour(tourId:"${this.$router.currentRoute.params.tourId}"){
              id
              automaticRefunds
              allowRegistrationMessage
              name
              isArchived
              scoringType
          		registrationMethod
              scoreBasedOnNrOfEvents
              isPublished
              isDeletable
              status
              eventsCount
              paymentType
              type{
                name
                type
              }
              connectedAccount{
                id
                name
              }
              events{
                id
                status
                isRegistrationOpen
                registrationsCount
              }
              administrators{
                userId
                role
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.tourData = result.data.data.tour;
          this.me = result.data.data.me;
          this.tourTypes = result.data.data.tourTypes;

          this.loading = false;
          this.$store.dispatch("loadManagedEvents");
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.tour-settings-title {
  font-size: 16px;
  margin-bottom: 18px;
}
.tour-settings-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 6px;

  h4 {
    font-size: 18px;
    margin: 0;
  }
  span {
    display: flex;
  }
}
h5 {
  font-size: 16px;
  margin-bottom: 24px;
  @include Gilroy-Bold;
}

a {
  color: $teal;

  transition: 0.3s;

  &:hover {
    color: $teal;
    text-decoration: underline;
  }
}

.tour-settings {
  padding: 0 12px;
  .section {
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 3px $sleet;
    margin-bottom: 24px;

    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &.extra-margin {
        margin-top: 18px;
      }

      .refund-wrap {
        margin-top: 18px;
        b {
          display: inline-block;
          line-height: 18px;
          svg {
            margin-right: 8px;
          }
        }
      }

      .tour-type-radio {
        width: 100%;
      }

      .empty {
        color: $sleet;

        option {
          color: $midnight;
        }
      }

      b {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        margin: 0;

        b {
          width: auto;
          display: inline-flex;
          margin: 0;
        }
      }

      .default-toggle {
        margin-left: 30px;
      }

      &.vertical {
        flex-direction: column;
        align-items: flex-start;

        &.archive-tour {
          margin-bottom: 24px;
        }
      }

      .archive {
        color: $teal;
        cursor: pointer;
      }
      .delete {
        color: $dusk;
        cursor: pointer;
      }

      .left {
        width: 100%;
        border-bottom: 1px solid $sleet;
        padding-bottom: 30px;
      }

      .right {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-top: 30px;

        .default-toggle {
          margin-left: 0;
          margin-bottom: 1rem;
        }

        .bestof-top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .default-toggle {
            margin: 0;
          }
        }

        .best-of-text {
          margin-top: 8px;
        }
      }
    }
  }
  .number-of-events {
    margin: 18px 0 0 0;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tour-settings-title {
    font-size: 18px;
    margin-bottom: 32px;
  }
  .tour-settings-header {
    margin-bottom: 48px;

    h4 {
      font-size: 24px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .tour-settings {
    .number-of-events {
      margin: 0;
      width: 100px;
    }
    .section {
      padding: 30px 30px 40px 30px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 3px $sleet;
      margin-bottom: 24px;

      .content {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &:nth-of-type(2) {
          border-top: 1px solid $sleet;
          margin-top: 30px;
          padding-top: 30px;
        }

        .tour-type-radio {
          width: 33%;
          margin-bottom: 12px;
        }

        .left {
          width: 40%;
          border-right: 1px solid $sleet;
          border-bottom: 0px solid $sleet;
        }

        .right {
          display: flex;
          justify-content: space-between;
          padding-left: 40px;
          width: 60%;
          flex-wrap: wrap;
          align-items: center;
          padding-top: 0;

          .selection-boxes {
            display: flex;
            width: 100%;
            margin-top: 20px;

            ul {
              list-style: none;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              width: 100%;
              margin: 0;
              padding: 0;

              li {
                height: 48px;
                width: 48px;

                &.active {
                  color: white;
                  transition: 0.3s;
                  @include Gilroy-Bold;
                }
              }
            }
          }

          .number-of-events {
            margin-top: 18px;
            width: 150px;
          }
        }

        &.vertical {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      select {
        max-width: calc(50% - 20px);
      }
    }
  }
  h5 {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
</style>
